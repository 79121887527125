@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800";
@import "https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800|Open+Sans";

body {
    font-size: 14px;
    line-height: 24px;
    font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
    color: #373c46;
    font-family: "Montserrat", sans-serif;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.01);
}

h1,
h2,
h3 {
    margin: 0 0 25px;
}

h3 {
    font-size: 22px;
    position: relative;
}

a {
    color: #0ea3a7;
}
a:hover {
    color: #333;
}
.marginBottom-0 {
    margin-bottom: 0;
}

button.btn {
    /*border: none ;*/
    /*text-transform: uppercase;*/
    font-weight: 600;
    font-size: 14px !important;
    letter-spacing: 0.3px;
    font-family: "Montserrat", sans-serif;
    /*box-shadow: 0 5px 9px rgb(176, 215, 218);*/
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
}

button.btn:hover,
button.btn:focus {
    background: #03c1cf;
    outline: none;
    box-shadow: none;
}

/*=================================================
                  spinner
=====================================================*/
/*.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(../../spinner/spinner.gif) 50% 50% no-repeat #fff;
    opacity: 1;
}*/
/*=================================================
                  header
===================================================*/
.dropdown-submenu {
    position: relative;
}
.dropdown-submenu > .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -35px;
    margin-left: -1px;
    -webkit-border-radius: 0 6px 6px 6px;
    -moz-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
}
/*.dropdown-submenu>a:after{display:block;content:" ";float:right;width:0;height:0;border-color:transparent;border-style:solid;border-width:5px 0 5px 5px;border-left-color:#cccccc;margin-top:5px;margin-right:-20px;}*/
.dropdown-submenu:hover > a:after {
    border-left-color: #555;
}
.dropdown-submenu:hover {
    border-left-color: #555;
}
.dropdown-submenu.pull-left {
    float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
    left: -100%;
    margin-left: 10px;
    -webkit-border-radius: 6px 0 6px 6px;
    -moz-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
}
.dropdown-menu > li > a {
    padding: 8px 25px 5px 10px;
    font-family: "Montserrat", sans-serif;
}

.dropdown-menu {
    min-width: 210px;
    padding: 15px 0px 10px 0px;
}

span.cat-tongle {
    float: right;

    cursor: pointer;
    display: block;
    content: " ";
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;

    /*----swati------*/
    /*	border-width:7px 0 7px 7px;
	 margin-top:-20px;*/
    margin-top: -25px;
    border-width: 8px 0 10px 10px;
    /*----swati------*/
    border-left-color: #cccccc;
    margin-right: 7px;
}

ul#long-menu {
    max-height: 400px; /* you can change as you need it */
    overflow: auto; /* to get scroll */
}

.dropdown-menu > li > a:hover {
    background-color: #09a2ae;
    color: #000;
}
.fixed-me {
    transition: all 1s;
}

.fixed-me {
    background-color: #fff !important;
    position: fixed;
    top: 0;
    height: 60px !important;
    z-index: 888;
    width: 100%;
    padding-top: 5px !important;
    -webkit-box-shadow: 0 1px 5px 0 rgba(96, 96, 96, 0.3);
    -moz-box-shadow: 0 1px 5px 0 rgba(96, 96, 96, 0.3);
    box-shadow: 0 1px 5px 0 rgba(96, 96, 96, 0.3);
    -webkit-transition: 0.5s all ease;
    transition: 0.5s all ease;
}
.navbar-me {
    background: #fff;
    transition: all 1s;
    border-radius: 0;
}
.shrink-nav {
    background: #fff;
}
.navbar {
    border: #000 0px solid;
    height: 70px;
}
.sidebar-left .navbar {
    height: auto !important;
}
.sidebar-left .container-fluid {
    padding-right: 9px;
    padding-left: 9px;
}
.sidebar-left .dashuser-img {
    width: 96px;
    height: 96px;
    border: #e2e2e2 1px solid;
    margin: 15px auto 15px auto;
    border-radius: 70%;
    overflow: hidden;
}
.sidebar-left .dashuser-img img {
    width: 100%;
}
.sidebar-left .navbar-inverse {
    background: none !important;
    background: none !important;
    border-right: #dddddd 0px solid;
    border-radius: 0px;
}
.sidebar-left .navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background-color: #43b7b9;
}
.sidebar-left .navbar-inverse .navbar-toggle {
    border-color: #43b7b9;
    color: #ccc !important;
    background: #43b7b9;
}
.sidebar-left .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #ffffff;
}
.navbar-collapse {
    background: #fff;
}

/*--------- sticky-------------*/
.sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    background: #ffffffba;
}

.navbar .btn {
    font-size: 14px;
    border-radius: 3px;
    background: #0ea3a7;
    border: #0ea3a7;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0 5px 9px rgb(176, 215, 218);
    margin: 24px 0px 0 12px;
    padding: 6px 16px 6px 12px;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    align-self: center;
}

.col-md-offset-2 {
    margin-left: 16.66666667%;
}

.navbar .btn a {
    color: #fff;
    text-decoration: none !important;
}

.navbar .btn:hover {
    color: #fff;
    background: #03c1cf;
    box-shadow: 0 5px 9px rgb(176, 215, 218);
    text-decoration: none !important;
}

.navbar-light .navbar-nav .nav-link {
    color: #222;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    padding: 8px 12px;
    text-transform: uppercase;
}

.navbar-light .navbar-nav .open .nav-link {
    color: #03c1cf;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #03c1cf;
}
.dropdown-item {
    color: #606060;
    text-transform: uppercase;
    font-size: 13px;
}
.dropdown-item:hover {
    color: #fff;
    background-color: #578bff;
}
/*============================================
               content area
===============================================*/
.hero {
    padding: 0px 0 20px 0;
    background: url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/banner.jpg)
        no-repeat center center;
    background-size: cover;
}
.hero .intro {
    padding: 100px 0 75px 0;
}
.hero .intro h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 44px;
    font-weight: 600;
    line-height: 1.1;
    letter-spacing: 0;
    color: #373c46;
}

.hero .intro h1 span {
    font-size: 44px;
    font-weight: 600;
    color: #049fa3;
}

.hero .intro p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    color: #222;
    max-width: 700px;
    margin: 0 auto;
    margin-top: 25px;
}
.breadcrumb {
    margin-bottom: 0;
    font-size: 15px;
    padding: 12px 0px;
}

.btn-action {
    color: #fff;
    margin: 24px 5px 24px 0px !important;
    text-transform: uppercase;
    font-weight: 600;
}

.pricing-container {
    padding: 70px 0px;
    background: #fff;
    border-top: #edf0ef 1px solid;
    border-bottom: #edf0ef 1px solid;
    display: block;
}

.pricing-container .intro h4 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    color: #03bfce;
    text-transform: uppercase;
    margin-bottom: 2px;
}
.pricing-container .intro h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 33px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #373c46;
}

.pricing-container .intro p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.6;
    color: #222;
    margin-bottom: 20px;
}

.pricing-container .table-left,
.pricing-container .table-right {
    padding: 40px 15px 30px 15px;
    margin: 0 auto;
    /*  margin-bottom: 30px; 
	   box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.29);
   */
    background-color: #f1f1f4;

    box-shadow: 0px 13px 20px 0px rgba(0, 0, 0, 0.29);
    border: transparent;
    border-radius: 10px;
    max-width: 400px;
    min-height: 438px;
}
.slide-text-wrap h6 {
    text-align: right;
    color: #fff;
    margin: 10px 0px; /*yashi */
}
.table-left .pricing-details h2,
.table-right .pricing-details h2 {
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #333;
    margin-bottom: 10px;
}

.table-left .pricing-details span,
.table-right .pricing-details span {
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    font-size: 28px;
    font-weight: 400;
    color: #222;
    margin-bottom: 0px;
}

.pricing-container .table-left .pricing-details ul,
.pricing-container .table-right .pricing-details ul {
    margin-bottom: 10px;
}

.table-left .pricing-details ul,
.table-right .pricing-details ul {
    margin-top: 20px;
    margin-bottom: 50px;
    padding: 0;
    list-style: none;
}

.table-left .pricing-details li,
.table-right .pricing-details li {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #222;
    line-height: 1.4;
    margin-bottom: 10px;
}
.btn-action {
    padding: 1rem 1.5rem;
    background: #0ea3a7;
    border-color: #0ea3a7 !important;
    border-radius: 0.125rem;
    box-shadow: 0 5px 9px rgb(176, 215, 218);
    margin: 24px 0px;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    font-size: 14px;
}

.rowtext {
    width: 768px;
    margin: 16px auto;
    padding: 0px 0px;
    overflow: hidden;
}

.btn-action:hover {
    color: #ffffff;
    border-color: #03c1cf !important;
    background: #03c1cf !important;
    -webkit-transform: translateY(-2px);
    -moz-transform: translateY(-2px);
    transform: translateY(-2px);
}
.btn-primary.focus,
.btn-primary:focus {
    background: #03c1cf !important;
}

.testimonial {
    /* margin-top: -164px;*/
    padding: 0px 0px;
    background: #f8f8f8;
    width: 100%;
    border-bottom: #ccc 0px solid;
    text-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
    position: relative;
}
.carousel-caption {
    text-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
}
.testimonial .carousel-control-prev,
.testimonial .carousel-control-next {
    display: none;
}

.testimonial .carousel-indicators {
    position: absolute;
    right: 0;
    margin-right: 20%;
    margin-left: 20%;
    bottom: 0px;
}

.testimonial .carousel-indicators li {
    width: 12px;
    height: 12px;
    background: #fff;
    border: #d0cece 1px solid;
    border-radius: 70%;
}

.testimonial .carousel-indicators .active {
    background: #fff;
    border: #d0cece 1px solid;
}

.testimonial .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-height: 300px;
    min-height: 300px;
}

.testimonial .carousel-caption .carouselimg {
    width: 100px;
    height: 100px;
    margin: 0px auto 15px auto;
    border-radius: 70%;
    overflow: hidden;
}

.testimonial .carousel-caption h3 {
    color: #222;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 1.1rem;
    line-height: 21px;
    text-align: center;
    padding-bottom: 25px;
}

.testimonial .carousel-caption h3 span {
    color: #0ea3a7;
    font-size: 12px;
}

.testimonial .carousel-caption {
    position: absolute;
    right: 8%;
    top: 10%;
    left: 8%;
    z-index: 10;
    padding-top: 6px;
    padding-bottom: 12px;
    color: #fff;
    text-align: center;
}

.testimonial .carousel-caption p {
    color: #222;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
}
.testimonial .carousel-caption .btn {
    font-size: 13px;
    border-radius: 2px;
    margin: 0px 3px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    padding: 9px 14px;
    text-transform: uppercase;
    box-shadow: 0 5px 9px rgba(0, 123, 255, 0.32);
    margin: 24px 12px 0 0;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
}

.download {
    height: 100%;
    background: #f2f2f2;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
        url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/download-bg.jpg)
            no-repeat center center;
    background-size: cover;
    padding: 137px 0;
}

.dwnld-inner {
    padding: 0px 0px;
    overflow: hidden;
}

.dwnld-inner .app-info h4 {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    line-height: 1.7;
    margin: 0 0 25px 0;
}

.dwnld-inner .app-info h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 34px;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    letter-spacing: -1px;
    margin: 20px 0 20px 0;
}

.footer {
    background: #ffffff;
    padding: 70px 0px 0px 0px;
    border-top: #e5e5e5bd 1px solid;
}
.footer .ft-left p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #222;
    line-height: 1.7;
    letter-spacing: 0;
    margin-top: 15px;
    width: 68%;
    font-weight: 400;
}

.footer .ft-left .footer-img {
    padding: 0 0 10px 0;
}
.ft-right {
    padding: 0px 0 0 0;
}
.ft-right ul {
    list-style-type: none;
    -webkit-columns: 2;
    columns: 2;
    padding: 0;
}
.ft-right ul li {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 2.5;
    text-transform: uppercase;
}
.ft-right ul li a {
    color: #333;
    text-decoration: none;
}
.ft-right ul li a:hover {
    color: #0ea3a7;
    text-decoration: none;
}
.footer-bottom {
    bottom: 0;
    text-align: center;
    margin-top: 70px;
    border-top: #e5e5e5 1px solid;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    color: #222;
    padding: 9px 0px;
}
.footer-bottom .right {
    text-align: right;
}
/*========================================
             Forgot
==========================================*/
.loginbg.fp {
    padding: 8.5% 0;
}
/*========================================
             login
==========================================*/
.loginbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: #f2f2f2;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
        url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg.jpg)
            no-repeat center center;
    background-size: cover;
    padding: 3.29% 12px;
}
.loginbg .form-signin {
    margin: auto;
    padding: 39px 50px;
    background: #ffffff;
    border: #e5e5e5 1px solid;
    width: 536px;
    border-radius: 6px;
}
.loginbg .form-signin p {
    font-size: 14px;
    text-align: center;
}

.loginbg .form-signin .error {
    color: #fb1c0d;
    margin-top: 1px;
    font-size: 13px;
}

.loginbg .form-signin .alert-danger {
    color: #e30a35;
    background-color: #fe9c95;
    border-color: #f5c6cb;
    text-align: left;
}

.loginbg .form-signin .alert-success {
    color: #155724;
    background-color: #9bf6b2;
    border-color: #c3e6cb;
}

.loginbg .form-label-group {
    margin-bottom: 18px;
}
.loginbg .form-label-group label {
    font-size: 14px;
    font-weight: 700; /* yashi 600 to 700 */
    text-transform: uppercase;
    color: #333;
}

.loginbg .form-control {
    padding: 11px 10px;
    font-size: 14px;
    border-radius: 3px;
    height: 45px;
    background-color: #ffffff;
    color: #858584;
}
.loginbg .font-weight-bold {
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 34px;
    margin-bottom: 20px;
    color: #373c46;
}

/*========================================
             Register
==========================================*/
.registerbg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    height: 100%;
    background: #f2f2f2;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)),
        url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/login-bg.jpg)
            no-repeat center center;
    background-size: cover;
    padding: 0px 12px;
    background-position: right;
}

.registerbg .form-signin {
    margin: 4% auto;
    padding: 39px;
    background: #ffffff;
    border: #e5e5e5 1px solid;
    width: 100%;
    max-width: 700px;
    border-radius: 6px;
}
.registerbg .form-signin label {
    font-size: 14px;
    font-weight: 700 !important;
    text-transform: uppercase;
    color: #333;
}

.registerbg .form-signin label .label-text {
    font-size: 14px;
    font-weight: 400;
    color: #222;
    text-transform: none;
}
.registerbg .form-signin label .label-text3 {
    font-size: 16px;
    font-weight: 400;
    color: #222;
    text-transform: none;
    float: left;
    margin-top: 22px !important;
    margin-left: 9px !important;
}

.form-signin p span {
    font-size: 13px;
    color: #858584;
}

.registerbg .form-label-group {
    margin-bottom: 10px;
}
.registerbg .form-control {
    padding: 11px 10px;
    font-size: 14px;
    height: 45px;
    border-radius: 3px;
    background-color: #ffffff;
    color: #858584;
}
.registerbg .font-weight-bold {
    font-weight: 400 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 23px;
    /* margin-bottom: 20px; yashi */
    margin: 20px 0px 10px !important;
    color: #373c46;
}
.registerbg .form-control2 {
    padding: 12px 9px;
    font-size: 14px;
    background-color: #ffffff;
    color: #858584;
    width: 100%;
    height: 45px;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

/*contactUs*/

.about-banner1 {
    position: relative;
}
.about-banertext {
    position: absolute;
    text-align: center;
    top: 34%;
    left: 24%;
    right: 24%;
}

.about-banertext h1 {
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    font-weight: 700;
}
.about-banertext p {
    font-size: 14px;
    color: #fff;
    padding: 0px 15px;
    line-height: 21px;
    font-family: "Montserrat", sans-serif;
    font-weight: normal !important;
}
.contac-section {
    background: #000 #fff none repeat scroll 0 0;
    padding: 70px 0px;
}
.contac-service {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #e2e2e2;
    margin-bottom: 30px;
    padding: 25px 19px;
    width: 100%;
}
.contac-service:hover {
    background: #f9f7ff none repeat scroll 0 0;
    border: 1px solid #04bbc9;
}
.contac-service .icon-box {
    border: 1px solid #b4d4d6;
    border-radius: 50%;
    color: #04bbc9;
    float: none;
    font-size: 39px;
    font-weight: 300;
    height: 90px;
    line-height: 90px;
    margin: 15px auto 0;
    text-align: center;
    width: 90px;
}
.contac-service .icon-box:hover {
    background: #04bbc9 none repeat scroll 0 0;
    border: 1px solid #04bbc9;
    color: #fff;
}
.contac-service h1 {
    font-weight: 600 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 21px;
    margin-top: 20px;
    color: #373c46;
}
.contac-service p {
    color: #222;
    font-size: 14px;
    padding: 0px 15px;
    margin-bottom: 25px;
    font-family: "Open Sans", sans-serif;
    font-weight: normal;
}
.contac-service p a {
    color: #222;
    text-decoration: none;
}
.contac-service p a:hover {
    color: #007bff;
    text-decoration: none;
}
.touchcreative-section {
    background: #000 #fff none repeat scroll 0 0;
    padding: 0px 0px;
    margin-bottom: 0px;
}

.touchcreative-section .bottom {
    margin-bottom: 40px;
}
.touchcreative-section .col-sm-6,
.col-xs-12 {
    min-height: 1px;
    padding-left: 0px;
    padding-right: 0px;
}
.touchcreative-section .row {
    margin-left: -0px;
    margin-right: -0px;
}
.touchleft {
    margin: 0px 0px;
    padding: 31px 56px;
    background: #fff;
}

.touchright h1 {
    color: #fff;
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    margin: 0px 0px;
    padding: 0px 0px 11px 0px;
}
.touchright p {
    margin: 0px 0px;
    padding: 0px 0px 32px 0px;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #fff;
    float: left;
}
.touchright {
    margin: 0px 0px;
    padding: 31px 30px;
    background: #6498fc;
    height: 100%;
}
.touchright .col-sm-6,
.col-xs-12 {
    min-height: 1px;
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    color: #222;
    font-weight: 400;
}
.touchright .row {
    margin-left: -0px;
    margin-right: -0px;
}
.touchright .form-control {
    background-color: #ffffff94;
    background-image: none;
    border: 1px solid #b5cfff;
    border-radius: 0;
    color: #25324b;
    display: block;
    font-size: 15px;
    margin-bottom: 28px;
    height: 45px;
    padding: 11px 20px;
    width: 100%;
    border-radius: 2px;
}

.touchright .form-control::-moz-placeholder {
    color: #7e98c9;
    opacity: 1;
}

.touchright .btn {
    border: 1px solid #4829a0;
    border-radius: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.42857;
    padding: 11px 37px;
    text-transform: uppercase;
}
.touchright .btn-default {
    background-color: #095cfb;
    border-color: #095cfb;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
}
.touchright .btn-default:hover {
    background-color: #0451e3;
    border-color: #0451e3;
    color: #fff;
}

.touchcreative-section2 .bottom {
    margin-bottom: 40px;
}

.weare-section {
    background: #fff;
    padding: 70px 0px;
}

.weare-section .btn {
    font-size: 1.5rem !important;
    font-weight: 400;
    color: #0ea3a7 !important;
}

.weare-section .card {
    margin-bottom: 25px;
}
.weare-section .bottom {
    margin-bottom: 45px;
}

.weare-section h1 {
    color: #373c46;
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    text-align: left;
    margin: 0px 0px;
    padding: 0px 0px 11px 0px;
}
.weare-section p {
    padding: 0px 0%;
    color: #222;
}
.weareimg {
    border: #d0cfcf 1px solid;
    width: 262px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0px auto;
}
.weareimg2 {
    border: 0px solid #d0cfcf;
    width: auto;
    overflow: hidden;
    padding: 12px;
}
.weareimg2 img {
    width: 100%;
    height: 304px;
}
.wearetext-right h3 {
    font-size: 24px;
    margin: 0px 0px;
    padding: 0px 0px 20px 0px;
    color: #222;
    font-family: "Montserrat", sans-serif;
}
.wearetext-right p {
    font-size: 14px;
    line-height: 24px;
    text-align: justify;
    margin: 0px 0px;
    padding: 0px 0px;
    color: #222;
    font-family: "Montserrat", sans-serif;
}

.searchbg {
    height: 100%;
    background: #fff;
    padding: 60px 0;
}
.searchbg .form-contact {
    margin: 1% auto;
    padding: 0px 40px;
    background: #fff;
    border: #e5e5e5 0px solid;
    width: 80%;
    border-radius: 0px;
}

.searchbg .form-contact h3 {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 44px;
    color: #222;
}

button.btn.btn-lg.btn-primary.btn-block.mt-4.sbtn {
    width: 130px !important;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.3px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0 5px 9px rgb(176, 215, 218);
    border: none;
}
button.btn.btn-lg.btn-primary.btn-block.mt-4.sbtn:hover,
button.btn.btn-lg.btn-primary.btn-block.mt-4.sbtn:focus,
button.btn.btn-lg.btn-primary.btn-block.mt-4.sbtn:active {
    outline: none;
    box-shadow: none;
    border: none;
}
form#search_form {
    margin: 20px 0;
    /* padding: 0; */
    /* width: 50%; */
}
.text-center.mb-4 {
    margin-bottom: 0px !important;
    position: relative;
    top: -10px;
}
.searchbg .form-signin {
    width: 45%; /*  yashi */
}

.searchbg .form-label-group {
    margin-bottom: 10px;
}

.searchbg .form-label-group label {
    font-size: 15px;
    font-weight: 400;
}

.searchbg .form-control {
    padding: 11px 10px;
    font-size: 14px;
    border-radius: 3px;
    height: 42px;
    background-color: #ffffff;
    color: #858584;
}
.searchbg .font-weight-bold {
    font-weight: 700 !important;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    color: #373c46;
    margin-top: 0;
}
.form-label-group.mt-5 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; /* yashi */
}

/* yashi start*/
.form-label-group.mb-5 {
    margin-bottom: 0px !important;
}
button.btn.btn-lg.btn-primary.btn-block.mt-4 {
    margin-top: 0px !important;
}
/* yashi end*/

h1.h3.mb-3.font-weight-bold.with-bg {
    background: #0ea3a7;
    padding: 6px 10px;
    line-height: 26px;
    color: #fff;
    text-align: left;
    font-size: 18px;
}
.searchbg .form-control2 {
    /*padding: 7px 9px;*/
    padding: 10px 9px;
    font-size: 14px;
    background-color: #ffffff;
    color: #858584;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 100%;
}

.forum-Section {
    height: 100%;
    background: #fff;
    padding: 70px 0;
    border-top: 1px solid #ced4da;
}

.forum-Section h1 {
    color: #373c46;
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: left;
    margin: 0px 0px 35px 0px;
    border-bottom: #ccc 1px solid;
    padding: 0px 0px 11px 0px;
}

.forum-Section .table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(162, 162, 162, 0.075);
}

.forum-Section .table th,
.table td {
    font-size: 14px;
}

.table .thead-dark th {
    color: #fff;
    font-family: "Montserrat", sans-serif;
    background: #053042;
    border-color: #686e78 !important;
}

.ads1 {
    border: #d0cfcf 0px solid;
    text-align: center;
    width: 100%;
    overflow: hidden;
    padding: 4px;
}

.sitemap ul {
    margin: 0px;
    padding: 0px 15px;
    list-style: none;
}

.sitemap li {
    list-style: none;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 17x;
    color: #373c46;
}

.sitemap li a {
    text-decoration: none;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    color: #09a2ae;
}

.sitemap li a:hover {
    text-decoration: none;
    color: #10808a;
}

.card-header {
    padding: 0.25rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.dashboard {
    background: #fff;
    padding: 0px 0;
    border-top: #d0cfcf 1px solid;
    border-bottom: #d0cfcf 1px solid;
}

.dashleft {
    /* background: #e9ecef82;
	 border: #e2e2e2 1px solid;*/
    padding: 0px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.dashleft ul {
    padding: 4px 15px;
    margin: 0px 0px;
    list-style: none;
}

.dashleft li {
    padding: 4px 0px;
    margin: 0px 0px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #6c757d;
    font-weight: 300;
    text-align: left;
}

.dashleft li a {
    color: #6c757d;
    text-decoration: none;
}

.dashleft li a:hover {
    color: #049fa3;
    text-decoration: none;
}

.dashleft h3 {
    padding: 4px 12px;
    margin: 0px 0px 0px 0px;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #fff;
    text-align: left;
    background: #004b6a;
}

.dashuser {
    padding: 0px 0px 4px 0px;
    margin-bottom: 25px;
    border-bottom: #e2e2e2 1px solid;
}

.dashuser h2 {
    padding: 0px;
    margin: 12px 0px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #049fa3;
    text-align: center;
}

.dashuser-img {
    width: 120px;
    height: 120px;
    border: #e2e2e2 1px solid;
    margin: 15px auto 15px auto;
    border-radius: 70%;
    overflow: hidden;
}

.dashuser-img img {
    width: 100%;
}

.dashright {
    background: #fff;
    padding: 15px;
    /*border:#e2e2e2 1px solid;*/
    border-radius: 4px;
}

.overviewbox {
    margin: 0px 0px;
    background: #fff;
    padding: 0px 0px 25px 0px;
}

.questext1 {
    margin: 0px 0px;
    background: #e4e5e6;
    padding: 6px 12px;
}

.questext1:hover {
    background: #99bfe8;
}

.questext2 {
    margin: 0px 0px;
    background: #fff;
    padding: 6px 12px;
}

.questext2:hover {
    background: #99bfe8;
}

.commentbox {
    padding: 8px 0px;
    margin: 0px 0px 25px 0px;
    width: 100%;
    float: left;
    background: #fff;
}

.commentbox a {
    color: #fff;
    text-decoration: none;
}

.commenttext {
    padding: 8px 15px;
    margin: 0px 0px 12px 0px;
    background: #0ea3a7;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    width: 100%;
    float: left;
}

.commenttext2 {
    padding: 8px 15px;
    margin: 0px 0px;
    min-height: 100px;
    background: #ffffff;
}

.commenttext3 {
    padding: 8px 15px;
    margin: 0px 0px 12px 0px;
    background: #d9d8d8;
    font-weight: 600;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
}

button.comment-delete {
    border: 0px;
    background: none;
    padding: 0.5rem;
}

button.comment-delete i.fa.fa-trash {
    color: gray;
}

.overviewtext {
    padding: 8px 15px;
    margin: 0px 0px 12px 0px;
    background: #0ea3a7;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: left;
}

.overviewtext3 {
    padding: 8px 15px;
    margin: 0px 0px;
    background: #09a2ae;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: #fff;
    text-align: left;
}

.overviewtext3 a {
    color: #fff !important;
}

.overviewtext2 {
    padding: 8px 15px;
    margin: 0px 0px 12px 0px;
    background: #d9d8d8;
    font-weight: 600;
    font-size: 13px;
    font-family: "Montserrat", sans-serif;
}

.text-align-right {
    text-align: right;
    float: right;
}

.text-align-right a {
    text-align: right;
}

.overviewbox h2 {
    padding: 9px 12px;
    margin: 0px 0px;
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    color: #6c757d;
    text-align: left;
}

.overviewbox h2 span {
    margin-right: 5px;
    font-weight: 600;
    color: #6c757d;
}

#score-tbl {
    text-align: center;
    width: 100%;
    height: 30px;
    font-weight: 700;
    margin-bottom: 15px;
}

#score-tbl td.green {
    background-color: #089408;
    color: #0f0;
    width: 33%;
    padding: 8px;
    margin: 0 2px 0 0;
}

#score-tbl td.red {
    background-color: #940808;
    color: red;
    width: 33%;
    margin: 0 2px;
}

#score-tbl td.gray {
    background-color: #c8c8c8;
    color: #666;
    width: 33%;
}

.membership {
    margin: 0px 0px;
    padding: 0px 12px;
    width: 100%;
    border: #000 0px solid;
}

.membership .form-control {
    padding: 11px 10px;
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
    background-color: #ffffff;
    color: #858584;
}

.membership .form-control2 {
    padding: 11px 10px;
    font-size: 14px;
    border-radius: 4px;
    height: 40px;
    border: 1px solid #ced4da;
    background-color: #ffffff;
    color: #858584;
    width: 100%;
}

.membership .form-label-group label {
    font-size: 15px;
    font-weight: 400;
    color: #212529;
}

.membership .input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group {
    z-index: 2;
    margin-left: -1px;
    padding: 10px 15px;
    font-size: 12px;
}

.membership .panel-primary {
    border-color: #0d8d98 !important;
}

.membership .panel-primary > .panel-heading {
    color: #ffffff;
    background-color: #0d8d98;
    border-color: #0d8d98;
}

.membership .panel-info {
    border-color: #bce8f1 !important;
}

.membership .panel-primary > .panel-heading .panel-title {
    color: #ffffff;
}

.membership .panel-title {
    margin-top: 0;
    padding: 11px 0px;
    font-size: 18px;
    font-weight: 600;
}

.membership .panel-info > .panel-heading {
    color: #428bca;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.membership .panel-info > .panel-heading .panel-title {
    color: #049fa3;
}

.membership .panel {
    margin-bottom: 20px;
    background-color: #fff;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.membership .panel:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4),
        0 1px 5px rgba(130, 130, 130, 0.35);
}

.membership .panel-body {
    padding: 0px;
    text-align: center;
}

.membership .the-price {
    background-color: rgba(220, 220, 220, 0.17);
    box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
    padding: 20px;
    margin: 0;
}

.membership .the-price h1 {
    line-height: 1em;
    padding: 0;
    margin: 0;
}

.membership .subscript {
    font-size: 25px;
}

/* CSS-only ribbon styles    */
.membership .cnrflash {
    /*Position correctly within container*/
    position: absolute;
    top: -9px;
    right: 4px;
    z-index: 1; /*Set overflow to hidden, to mask inner square*/
    overflow: hidden; /*Set size and add subtle rounding  		to soften edges*/
    width: 100px;
    height: 100px;
    border-radius: 3px 5px 3px 0;
}

.membership .cnrflash-inner {
    /*Set position, make larger then 			container and rotate 45 degrees*/
    position: absolute;
    bottom: 0;
    right: 0;
    width: 145px;
    height: 145px;

    -webkit-background-image: linear-gradient(
            90deg,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%
        ),
        linear1-gradient(0deg, transparent 0%, rgba(1, 1, 1, 0.2) 50%);

    background-image: linear-gradient(
            90deg,
            transparent 50%,
            rgba(255, 255, 255, 0.1) 50%
        ),
        linear-gradient(0deg, transparent 0%, rgba(1, 1, 1, 0.2) 50%);
    background-size: 4px, auto, auto, auto;
    background-color: #aa0101;
    box-shadow: 0 3px 3px 0 rgba(1, 1, 1, 0.5), 0 1px 0 0 rgba(1, 1, 1, 0.5),
        inset 0 -1px 8px 0 rgba(255, 255, 255, 0.3),
        inset 0 -1px 0 0 rgba(255, 255, 255, 0.2);
}

.membership .cnrflash-inner:before,
.membership .cnrflash-inner:after {
    content: " ";
    display: block;
    position: absolute;
    bottom: -16px;
    width: 0;
    height: 0;
    border: 8px solid #800000;
}

.membership .cnrflash-inner:before {
    left: 1px;
    border-bottom-color: transparent;
    border-right-color: transparent;
}

.membership .cnrflash-inner:after {
    right: 0;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.membership .cnrflash-label {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding-bottom: 5px;
    color: #fff;
    text-shadow: 0 1px 1px rgba(1, 1, 1, 0.8);
    font-size: 0.95em;
    font-weight: bold;
    text-align: center;
}

.membership .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.membership .panel-footer .btn {
    font-size: 1.5rem;
    padding: 0.595rem 0.95rem;
}

.loggedbox {
    margin: 0px 0px;
    padding: 0px 0px;
    width: 100%;
    border: #000 1px solid;
}

.forum-Section2 {
    height: 100%;
    background: #fff;
    padding: 0px 0;
}

.forum-Section2 h1 {
    color: #373c46;
    font-size: 34px;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    text-align: left;
    margin: 0px 0px 35px 0px;
    border-bottom: #ccc 1px solid;
    padding: 0px 0px 11px 0px;
}

.forum-Section2 .table th,
.table td {
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
}

.forum-Section2 .table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(162, 162, 162, 0.075);
}

.forum-Section2 .table-dark2,
.table-dark2 > th,
.table-dark2 > td {
    color: #212529;
    background-color: #e9ecef !important;
    border-color: #e9ecef !important;
}

.forum-Section2 .table .thead-dark th {
    color: #fff;
    background-color: #0ea3a7 !important;
    border-color: #0ea3a7 !important;
}

.questions-logged {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    border: #000 0px solid;
}

.questions-logged2 {
    margin: 0px 0px 25px 0px;
    padding: 0px;
    border: #000 0px solid;
}

.questions-logged2 .forum-logged .table .thead-dark th {
    color: #fff;
    background-color: #0ea3a7 !important;
    border-color: #0ea3a7 !important;
}

.questions-logged2 .table th,
.table td {
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
}

.questions-logged .table th,
.table td {
    font-weight: 300;
    font-family: "Montserrat", sans-serif;
}

.image-preview-input {
    position: relative;
    overflow: hidden;
    margin: 0px;
    color: #222;
    background-color: #fff;
    border-color: #ccc;
}

.image-preview-input input[type="file"] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.image-preview-input-title {
    margin-left: 2px;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 9px 0px;
    font-size: 1.8rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-facebook {
    color: #fff;
    background-color: #4b64a6;
    border-color: #4b64a6;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8px 0px;
    font-size: 2rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-facebook:hover {
    color: #fff;
    background-color: #4c68b4;
    border-color: #4b64a6;
}

.loginbg .form-label-group input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    right: 9000px;
}

.registerbg .form-label-group input[type="checkbox"],
input[type="radio"] {
    position: absolute;
    right: 9000px;
}

/*Check box*/
.registerbg .form-label-group input[type="checkbox"] + .label-text:before {
    content: "\f096";
    font-family: "FontAwesome";
    /*speak: none;*/
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 1em;
    display: inline-block;
    margin-right: 5px;
}

.registerbg
    .form-label-group
    input[type="checkbox"]:checked
    + .label-text:before {
    content: "\f14a";
    color: #09a2ae;
    animation: effect 250ms ease-in;
}

.registerbg .form-label-group input[type="checkbox"]:disabled + .label-text {
    color: #aaa;
}

.registerbg
    .form-label-group
    input[type="checkbox"]:disabled
    + .label-text:before {
    content: "\f0c8";
    color: #ccc;
}

/*Check box*/
.loginbg .form-label-group input[type="checkbox"] + .label-text:before {
    content: "\f096";
    font-family: "FontAwesome";
    /*speak: none;*/
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 1em;
    display: inline-block;
    margin-right: 5px;
}

.loginbg .form-label-group input[type="checkbox"]:checked + .label-text:before {
    content: "\f14a";
    color: #09a2ae;
    animation: effect 250ms ease-in;
}

.loginbg .form-label-group input[type="checkbox"]:disabled + .label-text {
    color: #aaa;
}

.loginbg
    .form-label-group
    input[type="checkbox"]:disabled
    + .label-text:before {
    content: "\f0c8";
    color: #ccc;
}

/*Radio box*/

.registerbg .form-label-group input[type="radio"] + .label-text:before {
    content: "\f10c";
    font-family: "FontAwesome";
    /*speak: none;*/
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 1em;
    display: inline-block;
    margin-right: 5px;
}

.registerbg .form-label-group input[type="radio"]:checked + .label-text:before {
    content: "\f192";
    color: #049fa3;
    animation: effect 250ms ease-in;
}

.registerbg .form-label-group input[type="radio"]:disabled + .label-text {
    color: #aaa;
}

.registerbg
    .form-label-group
    input[type="radio"]:disabled
    + .label-text:before {
    content: "\f111";
    color: #ccc;
}

/*Radio Toggle*/

.registerbg .form-label-group .toggle input[type="radio"] + .label-text:before {
    content: "\f204";
    font-family: "FontAwesome";
    /*speak: none;*/
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    width: 1em;
    display: inline-block;
    margin-right: 10px;
}

.registerbg
    .form-label-group
    .toggle
    input[type="radio"]:checked
    + .label-text:before {
    content: "\f205";
    color: #16a085;
    animation: effect 250ms ease-in;
}

.registerbg
    .form-label-group
    .toggle
    input[type="radio"]:disabled
    + .label-text {
    color: #aaa;
}

.registerbg
    .form-label-group
    .toggle
    input[type="radio"]:disabled
    + .label-text:before {
    content: "\f204";
    color: #ccc;
}

.btn-primary {
    background-color: #0ea3a7;
    border-color: #0ea3a7;
    color: #ffffff;
}

.btn-primary:hover {
    background-color: #03c1cf !important;
    border-color: #03c1cf !important;
    color: #ffffff;
}

#loginDivider {
    height: 1px;
    background-color: #e3e3e3;
    text-align: center;
    margin-bottom: 25px;
    margin-top: 25px;
}

#loginDivider .textInDivider {
    background-color: #fff;
    position: relative;
    top: -0.8em;
}

.creditCardInput {
    float: left;
    width: 100%;
    border-radius: 4px;
    border: #ced4da 1px solid;
    padding: 9px 10px;
    overflow: hidden;
}

.creditCardInput .crediticon {
    margin: 2px 2px;
    padding: 0px 0px;
    float: left;
}

.dashleft2 {
    background: #fff;
    padding: 0px;
    border: #e2e2e2 0px solid;
    margin-bottom: 35px;
    overflow: hidden;
}

.dashleft2 ul {
    padding: 12px 15px;
    margin: 0px 0px;
    list-style: none;
}

.dashleft2 li {
    padding: 4px 0px;
    margin: 0px 0px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #6c757d;
    font-weight: 300;
    text-align: left;
}

.dashleft2 li a {
    color: #6c757d;
    text-decoration: none;
}

.dashleft2 li a:hover {
    color: #049fa3;
    text-decoration: none;
}

@media (min-width: 320px) and (max-width: 480px) {
    .rightSidebar {
        display: none;
    }
    .navbar-toggle {
        position: relative;
        float: right;
        padding: 9px 10px;
        margin-top: 8px;
        margin-right: 15px;
        margin-bottom: 0px;
        background-color: transparent;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }

    .navbar {
        height: auto !important;
    }

    .fixed-me {
        height: auto !important;
    }

    .pricing-container .table-left,
    .pricing-container .table-right {
        margin-bottom: 26px;
    }

    .testimonial .carousel-indicators {
        margin-bottom: -34px;
    }

    .hero .intro {
        /* padding: 11px 0 8px 0; yashi */
        padding: 40px 0 8px 0;
    }

    .hero .intro h1 {
        font-size: 30px;
    }

    .hero .intro h1 span {
        font-size: 30px;
    }

    .hero .intro p {
        font-size: 13px;
        line-height: 18px;
    }

    .btn-action {
        padding: 0.7rem 0.7rem;
        font-size: 13px;
    }

    .pricing-container {
        padding: 41px 0px 19px 0px;
    }

    .footer-bottom {
        text-align: center;
    }

    .footer-bottom .right {
        text-align: center;
    }

    .about-banertext {
        top: 25%;
        left: 0%;
        right: 0%;
        margin: 0px;
        padding: 0px;
    }

    .about-banertext p {
        display: none;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .navbar-me {
        transition: all 1s;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
        margin: 0;
        height: 100px !important;
        padding: 25px 15px !important;
        z-index: 999;
    }

    .fixed-me {
        height: auto !important;
    }

    .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        padding: 8px 8px;
    }

    .navbar .btn {
        font-size: 10px;
        padding: 6px 7px;
    }

    .about-banertext {
        top: 17%;
        left: 19%;
        right: 19%;
    }

    col-md-3 {
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .navbar {
        height: auto !important;
    }

    .fixed-me {
        height: auto !important;
    }

    .navbar-light .navbar-nav .nav-link {
        font-size: 12px;
        padding: 8px 8px;
    }

    .navbar .btn {
        font-size: 10px;
        padding: 6px 7px;
    }

    .about-banertext {
        top: 17%;
        left: 19%;
        right: 19%;
    }
}

/* <!---------viraj----------->	 */
.ibox-title {
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background-color: #ffffff;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 0px 0 0;
    color: inherit;
    margin-bottom: 0;
    padding: 10px 15px 7px;
    min-height: 48px;
    border-radius: 0.55rem 0.55rem 0rem 0rem;
}

.ibox-title h5 {
    width: 50%;
    position: absolute;
}

.ibox {
    clear: both;
    margin-bottom: 25px;
    margin-top: 0;
    padding: 0;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.03);
    border-radius: 12px !important;
}

.float-e-margins .btn {
    margin-bottom: 5px;
}

.ibox-content {
    background-color: #ffffff;
    color: inherit;
    padding: 15px 20px 20px 20px;
    border-color: #e7eaec;
    border-image: none;
    border-style: solid solid none;
    border-width: 0px 0;
    border-radius: 0rem 0rem 0.55rem 0.55rem;
}

.ibox-content h1 {
    font-size: 22px;
    color: #292f2f;
}

.flot-chart {
    display: block;
    height: 200px;
}

.flot-chart-content {
    width: 100%;
    height: 100%;
}

.stat-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.stat-percent {
    float: right;
}

.text-navy {
    color: #1ab394;
}

.progress-mini,
.progress-mini .progress-bar {
    height: 5px;
    margin-bottom: 0;
}

.progress-small,
.progress-mini {
    margin-top: 5px;
}

.progress-bar {
    background-color: #1ab394;
}

.ibox-tools {
    display: block;
    float: none;
    margin-top: 0;
    position: relative;
    padding: 0;
    text-align: right;
}

.ibox-tools a {
    cursor: pointer;
    margin-left: 5px;
    color: #c4c4c4;
}

.breadcrumb-dash {
    margin: 0px 0px 25px 0px;
    padding: 6px 12px;
    background: rgba(255, 255, 255, 0.3);
    border-bottom: #000 0px solid;
}

.breadcrumb-dash ul {
    margin: 0px 0px;
    padding: 2px 15px;
}

.breadcrumb-dash ul li {
    margin: 0px 0px;
    padding: 0px 6px;
    list-style: none;
    float: left;
}

.breadcrumb-dash h2 {
    margin: 0px 0px;
    padding: 0px 0px;
    font-size: 22px;
    color: #292f2f;
}

/*********Mvikash***************/
#left_form {
    background-color: #0ea3a782 !important;
}

#left_form {
    padding: 60px 50px 30px !important;
    min-height: 425px !important;
    text-align: center;
    color: #fff;
}

#left_form h2 {
    font-weight: 300;
    color: #fff;
    margin: 15px 0;
    padding: 0;
    font-size: 36px;
}

#left_form p {
    font-weight: 300;
    color: #fff;
    font-size: 16px;
}

.ovr-view {
    padding-top: 25px !important;
}

.radio_input {
    margin-top: 10px;
}

.form-group {
    position: relative;
    margin-bottom: 15px;
}

::after,
::before {
    content: "";
    box-sizing: border-box;
}

.radio_input label {
    padding-left: 0;
    margin-right: 15px;
    color: #999;
}

.form-group label {
    font-weight: 400;
}

.iradio_square-grey {
    background-position: -120px 0;
}

.icheckbox_square-grey,
.iradio_square-grey {
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px 0 0;
    padding: 0;
    width: 22px;
    height: 22px;
    /*background: url(grey.png) no-repeat;*/
    border: none;
    cursor: pointer;
}

.form-group label {
    font-family: "Open Sans", sans-serif !important;
    font-size: 14px !important;
    color: #222 !important;
}

.main_question.wizard-header {
    font-weight: 600 !important;
    color: #222;
}

.dsh-rite {
    padding: 0px !important;
}

textarea.form-control {
    height: 100% !important;
}

h3.main_question.wizard-header strong {
    color: #999;
    /* font-weight: 400; */
    line-height: 48px;
    /* margin-bottom: 32px; */
}

.pull-right i.fa {
    font-size: 25px;
    color: #5f5c5c;
}
/*=====================================================
                    questions-logged
=======================================================*/
#middle-wizard {
    padding: 45px 15px 10px 15px !important;
}

#bottom-wizard {
    position: inherit !important;
    padding-right: 10px;
    float: right;
}

button.forward {
    background: #c8c8c8 !important;
    color: #fff;
}

button.backward {
    background: #c8c8c8 !important;
    color: #fff !important;
}

button.backward a {
    color: #fff !important;
}

button.forward:hover {
    background: #03c1cf !important;
    color: #fff;
}

button.backward:hover {
    background: #03c1cf !important;
}

textarea.form-control {
    border: 1px solid#ddd !important;
}

button.forward:hover a {
    color: #fff !important;
    text-decoration: none;
}

button.backward:hover a {
    color: #fff !important;
    text-decoration: none;
}

button.backward:before {
    display: none;
}

button.forward:before {
    display: none;
}

button.submit:before {
    display: none;
}

button.backward {
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
}

button.forward {
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
}

button.submit {
    padding: 10px 20px 10px 20px !important;
}

.author-avatar {
    max-width: 30px;
    margin-right: 6px;
}

.question-cat span {
    display: inline;
    vertical-align: middle;
}

.question-cat span.author-avatar img {
    max-width: 100%;
    height: auto;
}

.author-name {
    margin: 0 8px;
    color: #222;
}

span.user-badge {
    padding: 4px 10px;
    color: #fff;
    margin-left: 8px;
    /***margin-right: 15px;***/
    line-height: 20px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px; /* border radius */
    background-color: #0ea3a7 !important;
}

li.s-text {
    display: inline-grid;
    font-size: 14px;
}

li.s-text-1 {
    margin-left: 12px;
    text-align: center;
}

li.s-text-2 {
    margin-left: 12px;
    text-align: center;
}

li.s-text {
    text-align: center;
}

span.question-views {
    border-radius: 50%;
    border: 1px solid #ddd;
    text-align: center;
    height: 40px;
    width: 40px;
    padding-top: 8px;
    vertical-align: bottom !important;
    margin-bottom: 10px;
}

.ques-head a {
    font-size: 20px;
    color: #0ea3a7 !important;
    font-weight: 700;
}

.pull-right.for-only-q img {
    height: 25px;
    margin-top: -13px;
    margin-left: 0px;
}

.col-md-7 .pull-right {
    margin-right: 10px;
    padding-top: 10px;
}

button.backward {
    margin-right: 5px;
    border: none;
}

button.forward {
    margin-right: 5px;
    border: none;
}

button.forward a {
    color: #fff !important;
}

.question-statistic {
    padding-left: 0;
}

li.cmts-list {
    list-style: none;
    text-align: right;
}

.for-view-color {
    background-color: #1abc9c;
    color: #fff;
}

.for-asnswer-color {
    background-color: #f39c12;
    color: #fff;
}

.q-lbtm-content {
    padding-top: 52px;
}

.l-center-content {
    text-align: left;
}

.q-right-content {
    padding-top: 52px;
}

a.show-comments.active {
    color: #222;
}

/*a.show-comments.active:hover{
	color: #0ea3a7;
}*/

button.btn.btn-primary.btn-default.btn-close {
    margin-left: 12px;
    padding: 8px 22px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif !important;
    letter-spacing: 0.5px;
}

.btn-primary.btn-default.btn-sub {
    margin-left: 12px;
    padding: 8px 22px;
    font-size: 14px;
    font-weight: 600;
    font-family: "Open Sans", sans-serif !important;
    letter-spacing: 0.5px;
}

.question-statistic {
    padding-left: 0;
    padding-top: 10px;
    margin-bottom: 0;
}

.s-text-1 .for-asnswer-color {
    margin-left: 6px;
}

.feed-item img.profile-photo-sm {
    float: left;
    margin-right: 10px;
}

img.profile-photo-sm {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

a.profile-link {
    color: #0ea3a7;
}

.feed-item {
    text-align: left;
    margin-bottom: 13px;
    padding: 0 20px;
    position: relative;
}

.feed-item::before {
    border-left: 2px solid #0ea3a7;
    position: absolute;
    height: 30px;
    content: "";
    left: 0;
    top: 5px;
}

.feed-item img.profile-photo-sm {
    float: left;
    margin-right: 10px;
}

.feed-item .live-activity {
    border-bottom: 1px solid #f1f2f2;
}

.row.question-wrapper {
    border-bottom: 1px solid #e0e2e2;
    padding-top: 10px;
    padding-bottom: 12px;
}

.cmnt-history {
    /*  padding-top: 40px;*/
    padding-bottom: 20px;
}

/*=========================================================================================
                                 QUESTION PAGE NEW DESIGN
===========================================================================================*/

@media (max-width: 767px) {
    .text-right.for-bomb-icon {
        padding-top: 10px !important;
    }
}

.dashright.dass-wraper {
    /*background: #004b6a !important;*/
    display: flow-root !important;
    padding: 0px !important;
}

.text-right.for-bomb-icon a img {
    height: auto;
    width: 35px;
    margin-top: 5px;
}

.padd {
    padding: 0px !important;
    border-right: 0px solid #fff;
    box-shadow: 4px 0 5px -2px #072735;
    height: fit-content;
}

div#right-form {
    background: #004b6a;
    text-align: center;
    display: -webkit-inline-box;
    height: auto;
}

div#right-form img {
    cursor: pointer;
}

div#right-form figure {
    margin: 3px 3px 3px;
}

img.indic {
    opacity: 0;
    position: absolute;
    top: 173px !important;
    right: 23px;
    height: auto;
    width: 40px;
}

div#right-form:hover img.indic {
    opacity: 1;
}

.bomb-wrapper {
    display: flex;
}

.q-number-wrapper {
    display: flex;
}

.bomb-wrapper {
    padding: 0px 0px;
    background: #004b6a !important;
}

.text-right.for-bomb-icon {
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 10px;
}

.q-number-wrapper {
    padding: 10px 0;
    background: #c8c8c8;
    color: #222;
}

#middle-wizard.for-qs {
    background: #fff;
    padding: 30px 15px 10px 15px !important;
}

.text-right.for-only-q {
    float: right;
    /*  margin-top: 10px;*/
}

.q-number-wrapper.for-btn {
    background: #004b6a;
    padding: 10px 0 8px 0;
}

ul#question.and-dsc {
    padding: 0px;
    margin-top: -6px;
}

h3.main_question.wizard-header.q-text strong {
    color: #222;
    font-weight: 600 !important;
    font-size: 20px;
    line-height: 30px;
}

.question-views.clr {
    color: #fff !important;
}

.q-hover:nth-child(odd) {
    background: #efefef;
}

.q-hover {
    margin-bottom: 0px !important;
    margin-top: 0;
}

label.text-s {
    margin-bottom: 0px !important;
    width: 100%;
    padding-left: 5px;
    font-size: 20px !important;
    padding: 5px 5px;
}

.q-hover:hover {
    background: #d4deeb !important;
}

button.cmnts-dts.show-comments {
    background: none;
    border: 0px solid;
    color: #fff;
}

.form-group.adjust {
    /* margin-top: -10px; */
    margin-right: -10px;
    margin-left: -9px;
    display: block;
}

button.backward {
    background: #c8c8c8;
}

.author-name.cmt {
    color: #fff !important;
}

/*====================================================
                      dashboard dropdown-item
======================================================*/
ul.dropdown-menu.drop-adj.show {
    min-width: 200px;
}

.navbar > .container {
    padding: 0px;
}

.navbar .navbar-brand {
    margin-right: 0%;
    margin-left: -15px;
}

li.dropdown.after-login {
    list-style: none;
    padding-top: 10px;
}

a.dropdown-toggle.for-adj::after {
    display: none;
}

a.dropdown-toggle.for-adj {
    text-decoration: none !important;
    font-size: 14px;
    color: #222;
    min-height: 50px;
    padding: 15px 0px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ul.dropdown-menu.dropdown-user.adj {
    min-width: 160px;
    padding: 5px 0px;
    font-size: 14px;
    /* margin-top: 34px; */
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ul.dropdown-menu.dropdown-user.adj > li > a {
    font-size: 14px;
    /* margin-top: 34px; */
    padding: 3px 20px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ul.dropdown-menu.dropdown-user.adj > li > a:hover {
    background: #fff;
    color: #0ea3a7 !important;
}

.fa-fw {
    color: #0ea3a7 !important;
}

.userright-img {
    width: 40px;
    height: 40px;
    width: 40px;
    border: #e2e2e2 1px solid;
    margin: -9px 10px 0px 10px;
    border-radius: 70%;
    overflow: hidden;
    float: left;
}

.navbar-nav .mr-auto li a {
    padding: 25px 15px;
    min-height: 50px;
    color: #222;
}

/*===================================================
                     privacy and term
=====================================================*/
.wearetext-right.one-up p {
    padding-bottom: 20px;
    line-height: 26px;
}

h3.privacy-sub-head {
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 36px;
    font-weight: 600;
    font-size: 20px;
}

ul.privacy-list li {
    list-style: none;
    line-height: 28px;
}

ul.privacy-list {
    padding-bottom: 10px;
    padding-left: 0px;
}

p.p-sub {
    font-size: 16px;
    padding-bottom: 10px !important;
    font-weight: 600;
}

/*===================================================
                      faq
=====================================================*/

.btn-link:focus,
.btn-link.focus,
.btn-link:hover,
.btn-link:active {
    text-decoration: none !important;
    border-color: transparent !important;
    outline: 0;
}
/********************************************************
				Mvikash
*********************************************************/
img.mb-2 {
    border: 1px solid#ddd;
    border-radius: 50px;
    padding: 10px;
    display: none;
}

#bottom-wizard {
    width: 100%;
    text-align: center;
}

a:hover {
    text-decoration: none !important;
}

.cmnts-dts i.fa.fa-comment {
    color: #0ea3a7;
}

a.show-comments {
    color: #222 !important;
    text-decoration: none !important;
}

a.show-comments:hover {
    color: #0ea3a7 !important;
}

.error {
    color: red !important;
}

_::-webkit-full-page-media,
_:future,
:root .safari_only {
    color: #0ea3a7;
    background-color: #cccccc;
}

/*==========================================
                  code for safari_only
============================================*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    ::i-block-chrome,
    .navbar-light .navbar-nav .nav-link {
        display: block !important;
    }

    ::i-block-chrome,
    .navbar-expand-lg .navbar-collapse {
        display: -webkit-flex !important;
    }

    ::i-block-chrome,
    .navbar.navbar-me {
        padding-top: 10px;
        /* padding: 1.2rem 1rem; */
        border: #000 0px solid;
        height: 50px !important;
        display: block !important;
    }

    ::i-block-chrome,
    .navbar-brand > img {
        display: -webkit-inline-box !important;
    }

    ::i-block-chrome,
    ul.navbar-nav.mr-auto {
        display: block !important;
    }

    ::i-block-chrome,
    ul.navbar-nav.mr-auto li {
        display: -webkit-flex !important;
    }

    ::i-block-chrome,
    .navbar-nav > li {
        z-index: 999 !important;
    }
    /*------------ END header--------------*/

    ::i-block-chrome,
    .download {
        background: url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/download-bg.jpg)
            no-repeat center center !important;
    }

    ::i-block-chrome,
    .btn-action {
        padding: 1rem 1.5rem;
        background: #0ea3a7 !important;
        border-color: #0ea3a7 !important;
        border-radius: 0.125rem;
        box-shadow: 0 5px 9px rgb(176, 215, 218);
        margin: 24px 0px;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        transition: 0.2s;
        font-size: 14px;
    }

    ::i-block-chrome,
    .btn-action {
        color: #fff;
        margin: 24px 5px 24px 0px !important;
        text-transform: uppercase;
        font-weight: 600;
    }

    ::i-block-chrome,
    [type="reset"],
    ::i-block-chrome,
    [type="submit"],
    ::i-block-chrome,
    button,
    ::i-block-chrome,
    html [type="button"] {
        -webkit-appearance: inherit;
    }

    ::i-block-chrome,
    .pricing-container .table-left,
    ::i-block-chrome,
    .pricing-container .table-right {
        min-height: 450px !important;
    }
    /*---------------- question--------------------*/
    ::i-block-chrome,
    .q-number-wrapper {
        display: block !important;
        padding: 10px 0 31px !important;
    }

    ::i-block-chrome,
    .bomb-wrapper {
        display: flex !important;
        margin-top: -20px !important;
    }

    ::i-block-chrome,
    .col-md-7.text-left.for-only-q {
        display: flex;
        list-style: none;
    }

    ::i-block-chrome,
    .q-number-wrapper.for-btn.for-top-btn {
        background: #004b6a;
        padding: 10px 0 50px 0 !important;
        display: list-item !important;
        list-style: none !important;
    }

    ::i-block-chrome,
    ul#question.and-dsc {
        margin-top: 10px !important;
        list-style: none !important;
        display: -webkit-inline-box !important;
    }

    ::i-block-chrome,
    li.s-text {
        display: -webkit-inline-box !important;
        list-style: none !important;
    }

    ::i-block-chrome,
    .radio_input label {
        display: -webkit-inline-box !important;
    }
}
/*=========================================
              mozila hacking code
============================================*/
@-moz-document url-prefix() {
    .pricing-container .table-left,
    .pricing-container .table-right {
        min-height: 464px;
    }
}

/*===================================================
                  page details page
=====================================================*/
section#blog-page {
    padding: 90px 0;
    background: no-repeat center center;
    background-size: cover;
}

.pade-details-wrraper {
    background: #e8ebef;
    padding: 15px 0;
    padding-bottom: 5px;
}

.pull-left {
    float: left;
}

table.ccpw_table tr {
    float: left;
    margin-right: 8px;
    height: 46px;
    line-height: 26px;
    padding: 10px 16px;
    margin-bottom: 8px;
    background: #fff;
    border-radius: 5px;
    /* width: 100%; */
}

.all-cnt-button {
    background: #fff;
    margin-right: 10px;
    height: 46px;
    line-height: 46px;
    padding: 0 10px;
    margin-bottom: 8px;
    border-radius: 5px;
}

ul.post-elements {
    display: -webkit-inline-box;
    padding-left: 0px;
    list-style: none;
}

h1.section-head.about-head.text-capitalize {
    margin-bottom: 0px;
    font-size: 26px;
    line-height: 28px;
}

ul.post-elements li {
    padding-right: 10px;
    /* border-right: 1px solid; */
    line-height: 11px;
}

ul.post-elements li a {
    color: #848181;
}

.hot-post {
    color: red;
}

.mrg-left {
    margin-left: 0px;
}

h1.slider-bold-text.post-t {
    font-size: 44px;
    font-weight: 600;
    color: #fff;
}

.content-section {
    padding: 60px 0;
}

.blog-post-content h1 {
    text-align: center;
    border-bottom: 1px solid #ddd;
}

h1.about-head {
    padding-bottom: 15px;
}

h1.section-head {
    color: #222;
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 600;
}

.blog-article-text p {
    text-align: justify;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #222;
    font-weight: 300;
    font-family: "Open Sans", sans-serif;
}

.block-quote,
q {
    quotes: "" "";
    font-style: italic;
    font-size: 1.4em;
    line-height: 1.75em;
    margin: 48px;
    padding: 0;
    border-left: 0px;
}

blockquote.block-quote p {
    font-size: 20px;
    padding: 15px 0;
    line-height: 30px;
}

blockquote.block-quote:before,
q:before,
blockquote.block-quote:after,
q:after {
    content: " ";
    display: block;
    border-top: 2px solid #222;
    width: 36px;
}

.half-img-w {
    display: -webkit-inline-box;
}

img.img-half {
    text-align: left;
    float: left;
    padding-bottom: 20px;
    padding-right: 20px;
}

.blog-article-text ul {
    padding-left: 0;
    margin-left: 26px;
    padding: 15px 0;
}

.blog-article-text ul li {
    list-style: none;
    position: relative;
    padding-bottom: 5px;
}

.blog-article-text ul > li:before {
    content: "";
    background: transparent;
    position: absolute;
    top: 14px;
    left: -24px;
    width: 10px;
    height: 1px;
    border-top: 1px solid;
}

/*model for question*/
.modal {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
    width: 100%;
    height: 452px;
    background: #202121a6;
    margin: auto;
    height: auto !important;
    z-index: 9999;
}

.modal-content {
    margin: auto;
    display: block;
    width: auto !important;
    max-width: 700px;
    position: relative;
    max-height: 600px;
    border: 0px solid rgba(0, 0, 0, 0.2);
}

.modal-content {
    -webkit-animation-name: zoom;
    -webkit-animation-duration: 0.6s;
    animation-name: zoom;
    animation-duration: 0.6s;
}

.close {
    /* position: absolute;*/
    top: 14px;
    right: 400px;
    color: #17262c;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    opacity: 1;
}

@-webkit-keyframes zoom {
    from {
        -webkit-transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
    }
}

@keyframes zoom {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

/*===================================
             toggle==========
=====================================*/
.toggle.btn.btn-success {
    position: relative;
    overflow: hidden;
}

.toggle-group {
    position: absolute;
    width: 200%;
    top: 0;
    bottom: 0;
    left: 0;
}

label.btn.btn-success.toggle-on {
    position: absolute;
    top: -1px;
    bottom: 0px;
    left: 0;
    right: 50%;
    margin: 0;
    border: 0;
    border-radius: 2px;
    font-size: 18px;
}

.toggle-off {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    right: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
}

.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #ccc;
}

.toggle-handle {
    position: relative;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100%;
    width: 0px;
    border-width: 0 1px;
}

.btn-success {
    color: #fff;
    background-color: #0ea3a7;
    border-color: #0ea3a7;
}

label.btn.btn-success.toggle-on:hover,
label.btn.btn-success.toggle-on:focus,
label.btn.btn-success.toggle-on:active {
    color: #fff;
    background-color: #0ea3a7;
    border-color: #0ea3a7;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button.current {
    background: #0ea3a7 !important;
    border: 1px solid #0ea3a7 !important;
    color: #fff !important;
}

/*=========== table pagignation==================*/
div#example_wrapper {
    overflow: auto !important;
}

div#example_info {
    padding-top: 0;
    padding-right: 20px;
}

.dataTables_wrapper .dataTables_paginate a.paginate_button:hover {
    background: #03c1cf !important;
    border: 1px solid #03c1cf !important;
    color: #fff !important;
}

div#example_paginate {
    float: none !important;
    padding-top: 10px !important;
}

a#example_previous:hover {
    color: #fff !important;
}

.new-mdf {
    font-size: 25px;
    line-height: 30px;
    padding: 10px 20px;
}

img#myImg {
    /* height: 390px;*/
    width: 100%;
}

.onln-test-img {
    height: 394px;
    width: 100%;
}

/*=======================================================
                         responsive media query
============================================================*/
@media only screen and (max-width: 767px) {
    .text-right.for-bomb-icon a img {
        width: 25px;
        margin-top: 0px;
    }

    button.backward {
        margin-bottom: 10px;
    }

    .q-number-wrapper.for-btn {
        display: inline-block;
        width: 100%;
    }

    ul#question.and-dsc {
        padding: 0px;
        margin-top: -10px;
    }

    .col-md-7.text-left.for-only-q {
        text-align: center !important;
    }

    .text-right.for-only-q {
        text-align: center !important;
    }

    .dashleft {
        margin-top: 20px;
    }

    .modal-content {
        width: 100% !important;
        height: auto;
    }
}

@media only screen and (max-width: 767px) {
    ul.navbar-nav.mr-auto {
        line-height: 36px;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS styles go here */
    li.s-text {
        display: inline-table;
    }
    .modal {
        background: rgba(32, 33, 33, 0.6509803921568628);
    }
    div#right-form {
        padding: 1px;
        max-height: none;
    }
    img#myImg {
        height: auto;
    }
    .close {
        right: 20px;
        position: absolute;
    }
    ul.form-inline.my-2.my-lg-0.navbar-right {
        display: -ms-flexbox;
    }
    form.form-inline.my-2.my-lg-0 {
        display: -ms-flexbox;
    }
}

/*=========================================================
                   updated css
===========================================================*/
.breadcrumb {
    display: none;
}

.btn-all {
    font-size: 14px;
    border-radius: 3px;
    border: none;
    background: #0ea3a7;
    color: #fff;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    box-shadow: 0 5px 9px rgb(176, 215, 218);
    margin: 24px 15px 0px 0px;
    padding: 6px 16px 6px 12px;
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    transition: 0.2s;
    text-transform: uppercase;
}

.btn-all:hover,
.btn-all:active,
.btn-all:focus {
    background-color: #03c1cf;
    border-color: #03c1cf;
    color: #ffffff;
    outline: none;
    border: none;
    box-shadow: none;
}
/*====================================================
                      slider section
======================================================*/
section#slider {
    height: 660px;
    padding: 175px 0;
    background-position: center right;
    background-size: cover;
    /*background-image: linear-gradient(-136deg, #f1efefc4 0%, #f9f8f8 100%), url(../images/banner.jpg);*/
    background-image: url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/banner.jpg);
    overflow: hidden;
}

.eclips {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 69px;
}

.eclips img {
    width: 180px;
    z-index: 0;
}

.slide-img img {
    position: relative;
    width: 87%;
    bottom: -85px;
    z-index: 9;
    /*---------------swati------------*/
    /*height: 400px;*/
    bottom: -95px;
}

.slide-text-wrap {
    padding-left: 65px;
    top: 180px;
    position: relative;
    padding-right: 0;
    float: right;
}

.slide-text-wrap h1 span {
    font-size: 40px;
    font-weight: 500;
    padding-left: 10px;
    line-height: 1.263157894736842;
}

.slide-text-wrap h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0;
    color: #fff;
    margin-bottom: 25px;
    text-align: right;
    padding-left: 60px;
}

.para {
    margin-bottom: 30px;
}

p.or {
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    color: #fff;
    margin-bottom: 10px;
    width: auto;
    margin-left: 160px;
    padding-left: 274px;
}

.slider-btn-wrap .btn-all {
    text-transform: uppercase;
    padding: 12px 15px;
    font-size: 18px;
    width: 180px;
    margin-left: 233px;
}

.slider-btn-wrap {
    margin-left: 51px;
    margin-bottom: 40px;
    margin-top: 24px;
}

.slider-app-btn-wrap a img {
    width: 95px;
}

.slider-app-btn-wrap .btn-all {
    max-width: 235px;
    max-width: 235px;
    background: #fff;
    position: inherit;
    padding: 0;
    box-shadow: none;
    margin-right: 5px;
}

.slider-app-btn-wrap i.fab {
    font-size: 40px;
    float: left;
    padding-right: 10px;
}

h4.btn-text {
    text-align: left;
    font-size: 12px;
    color: #fff;
    float: left;
    margin-bottom: 0;
    line-height: 21px;
}

h4.btn-text span {
    font-size: 20px;
    font-weight: 500;
}

/*======================================
            About Section
========================================*/

.eclips-dec img {
    width: 155px;
}

.eclips-dec {
    position: absolute;
}

section#welcome {
    background: #f8f8f8;
    position: relative;
    padding-top: 111px;
    padding-bottom: 111px;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    box-shadow: 0 0.3125rem 1rem 0 rgba(0, 0, 0, 0.24);
}

.wel-text-wrap {
    padding: 35px 40px;
}

.heading {
    color: #373c46;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 50px;
    margin-top: 32px;
}

a.simple-link {
    color: #0ea3a7;
    text-decoration: none;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    border-bottom: 3px solid;
    line-height: 30px;
    padding: 5px 0px;
}

/*=============================== 
            up to date
==============================*/
.eclips-eng {
    position: absolute;
    right: 7px;
    top: -106px;
    width: 186px;
    z-index: 0;
}

section#screen {
    padding: 70px 0;
    position: relative;
    background-color: #f8f8f8;
}

.screen-q {
    width: 100%;
    text-align: center;
    margin-bottom: 47px;
}
/*===================================
                deck and engine
====================================*/

.text-wrap.p-0 {
    padding: 0 !important;
}

.text-wrap.h-202 {
    height: 340px !important;
}

.text-wrap.h-310 {
    height: 310px !important;
}

.engin-box-wrap .text-wrap {
    padding: 20px;
    max-height: 400px;
    height: auto;
    margin-bottom: 30px;
    box-shadow: 0 0.3125rem 1rem 0 rgba(0, 0, 0, 0.24);
}

.box-wrap {
    box-shadow: 0 0.3125rem 1rem 0 rgba(0, 0, 0, 0.24);
}

.text-wrap {
    padding: 20px;
    max-height: 400px;
    height: 365px;
    margin-bottom: 30px;
}

.img-wrap {
    text-align: center;
    text-align: center;
    overflow: hidden;
    height: 320px;
}

.img-wrap img {
    /* width: 100%; */
    height: 100%;
    /* Removing the height of 100% to make the image height auto adjust so that images keep their aspect ratio fix */
}

.ttl {
    font-size: 20px;
    font-weight: 600;
    color: #373c46;
    margin: 20px 11px 12px 11px;
}

ul.deck-list {
    padding-left: 18px;
    margin-top: 10px;
}

ul.deck-list li {
    padding: 2px 0;
}

ul.deck-list li {
    padding-left: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #373c46;
    text-decoration: none;
}

ul.deck-list li a:hover {
    color: #0ea3a7;
}

/*--------- media---------*/
@media only screen and (max-width: 767px) {
    .slide-text-wrap {
        padding-left: 0;
    }

    .eclips-eng {
        position: absolute;
        right: 0px;
        /*--------swati-------*/
        top: 380px;
        width: 87px;
    }

    .loginbg .form-signin {
        width: 100%;
    }
}

/*============================================================
                          modification- 2019
================================================================*/
/*--------------- blog post------------*/
.blog-ttl {
    position: relative;
    display: flow-root;
    padding-bottom: 25px;
}

ul.post-elements li a {
    line-height: 20px;
}

ul.post-elements li a.content-date.date {
    color: #ffffff;
    font-size: 14px;
    background: #0ea3a7;
    padding: 0px 10px 1px 10px;
    line-height: 18px;
    border-radius: 4px;
}

ul.post-elements li a.content-date.date:hover {
    background: #03c1cf;
}

.blog-post-img {
    height: 400px;
    overflow: hidden;
    position: relative;
    display: flex;
    width: 100%;
    text-align: center !important;
    margin: 0 auto;
    margin-bottom: 25px;
    border-radius: 5px;
}

.blog-post-img figure {
    margin: auto !important;
}

.blog-post-img img {
    height: 400px;
}
/*======================================
                pricing table
=========================================*/
.pricing-bg {
    padding: 70px 10px;
    padding-bottom: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #fff;
}

.row.price-wrap {
    padding-bottom: 30px;
    text-align: center;
    display: block !important;
}

.pricing-tables-item {
    position: relative;
    transition: all 0.3s ease;
}

.pricing-tables-item .main-pricing-content {
    position: relative;
    width: calc(100% - 30px);
    background-color: #fff;
    box-shadow: 20px 0 60px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 50px;
    z-index: 5;
    transition: all 0.3s ease;
}

.pricing-tables-item .main-pricing-content h2,
.pricing-tables-item .main-pricing-content h5 {
    text-align: center;
}

.pricing-tables-item .main-pricing-content .most-popular-plan {
    margin: -50px -50px 0 -50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    background: #053042;
    color: white;
    padding: 8px;
    line-height: normal;
}

.pricing-tables-item:hover {
    margin-bottom: -20px;
    margin-top: -20px;
}

.pricing-tables-item:hover .main-pricing-content {
    padding: 70px 50px;
}

.pricing-tables-item .rate {
    margin-bottom: 13px;
    margin-top: 0;
}

.pricing-tables-item .price {
    font-size: 36px;
    font-weight: 600;
}

.pricing-tables-item .plan-duration {
    font-size: 72px;
    font-weight: 600;
}

h2.h1.rate {
    color: #222;
}

.pricing-tables-item .pricing-title {
    color: #6987ab;
    font-weight: 600;
    line-height: 22px;
    font-size: 18px;
}

.pricing-tables-item .pricing-discount {
    text-align: center;
    color: grey;
}

.pricing-line {
    height: 4px;
    border-radius: 5px;
    margin-bottom: 30px;
}

.position-item {
    color: #333;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 4px;
}

.bg-1 {
    background: linear-gradient(45deg, #00344d, #1b94ce);
}

.bg-2 {
    background: linear-gradient(45deg, #fda582, #f7cf68);
}

.bg-3 {
    background: linear-gradient(45deg, #0ea3a7, #03c1cf);
}

.bg-pricing-content {
    position: absolute;
    width: calc(100% - 30px);
    height: calc(100% + 40px);
    right: 0;
    top: 30px;
    border-radius: 10px;
    text-align: center;
}

.bg-pricing-content .link-buy {
    color: #fff;
    position: absolute;
    bottom: 20px;
    text-transform: uppercase;
    width: 100%;
    left: 0;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}

/*------- faq--------*/
section.section.faq-bg {
    padding: 70px 0;
    background-color: #fff;
}

.card .card-header {
    background: #053042;
    color: #ffff;
    padding: 10px 10px;
    border-bottom: 0;
}

.forum-list > li .info-container .total-count {
    width: 20%;
    text-align: center;
    color: #6c757d;
    font-size: 0.875rem;
    font-weight: 400;
}

.forum-list > li .info-container .latest-post {
    width: 30%;
}

.forum-list > li .info-container .latest-post .title {
    font-size: 0.8125rem;
    line-height: inherit;
    margin: 0;
}

.delete-content {
    float: right !important;
}

.forum-list.forum-detail-list .media .badge {
    font-size: 0.63125rem !important;
}

.card-body {
    padding: 20px 10px 10px 10px;
}
.accordion:not(.accordion-collapsed) .card {
    border-bottom: 1px solid #edf4f8 !important;
}

.accordion .card {
    border: 1px solid #edf4f8;
    margin-bottom: 15px;
}

button.lnk-faq {
    cursor: pointer;
    font-size: 18px !important;
    font-weight: 400 !important;
    color: #edf4f8;
    font-family: "Open Sans", sans-serif;
}

.card-header > button.btn:hover,
.card-header > button.btn:active {
    color: #03c1cf;
    background: none;
    outline: none;
    box-shadow: none;
}

/*=====================================
            contact -bg
========================================*/
.contactbg {
    background: #fff;
    height: 100%;
    padding: 60px 0;
}

.contactbg .form-contact {
    margin: 1% auto;
    padding: 0px 40px;
    background: #fff;
    border: #e5e5e5 0px solid;
    width: 80%;
    border-radius: 0px;
}

.contactbg .form-control {
    padding: 11px 10px;
    font-size: 14px;
    border-radius: 3px;
    height: 42px;
    background-color: #ffffff;
    color: #858584;
}

.contactbg .form-control2 {
    /* padding: 7px 9px; */
    padding: 10px 9px;
    font-size: 14px;
    background-color: #ffffff;
    color: #858584;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    height: 100%;
}

input[type="search"] {
    border: 1px solid #04bbca;
}
/*========================================================
                  search page table modification
=========================================================*/
div#Mydiv {
    padding-bottom: 40px;
}

table#example > thead tr th:nth-child(2) {
    width: 100px !important;
}

table#example > thead tr th:nth-child(3) {
    width: 150px !important;
}

table#example > thead tr th:nth-child(4) {
    width: auto !important;
}

/*====================================================
                   404
======================================================*/
#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 410px;
    width: 100%;
    text-align: center;
}

.notfound .notfound-404 h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 230px;
    margin: 0px;
    font-weight: 900;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    background-size: cover;
    background-position: center;
}

.notfound .notfound-404 {
    height: 280px;
    position: relative;
    z-index: -1;
}

.notfound h2 {
    font-family: "Montserrat", sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
}

.go-home {
    padding: 10px 20px;
}
/*========================================
                 forgot password
=========================================*/
h1.forget-pass {
    margin-bottom: 5px;
    margin-top: 25px;
    font-size: 23px;
}
/*=========================================
                login reset
===========================================*/
.loginbg {
    overflow-y: auto;
}
/*------swati---------*/
#slider span {
    margin-left: 56px;
}

#slider h6 {
    color: #fff;
    margin-left: 106px;
}

.img_custom {
    height: 230px;
    width: 600px;
}

.cust_h {
    height: 50%;
    padding: 0px 30px 0px 30px;
}

/*---------- media----------*/
@media only screen and (max-width: 767px) {
    .pricing-tables-item {
        margin-bottom: 85px;
    }

    .pricing-tables-item .main-pricing-content {
        padding: 30px;
    }
}
@media only screen and (max-width: 768px) {
    .pricing-tables-item .price {
        font-size: 40px;
    }

    .pricing-tables-item .main-pricing-content {
        padding: 50px 15px;
    }
}

.cus_txt_20 {
    letter-spacing: 0.2px;
    color: #373c46;
    font-weight: 600;
    font-size: 40px !important;
    line-height: 50px;
}

.cus_txt_fw {
    font-weight: 300;
}

.cust_vm {
    height: 416px;
}

.deck-list span {
    padding-left: 10px;
}

.vm_img {
    height: 389px;
    margin-left: 139px;
}

.vm_video {
    transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(0.5deg)
        scaleY(1.15);
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .cust_h {
        height: 50%;
        padding: 0px 14px 0px 14px;
    }

    .testimonial {
        margin-top: -30px;
    }

    .eclips-dec {
        position: absolute;
    }

    .vm_img {
        height: 375px;
        margin-left: 24px;
    }

    section#welcome {
        padding-top: 238px;
    }

    #slider h6 {
        margin-left: 109px;
    }

    section#screen {
        padding: 155px 0;
        position: relative;
    }

    .eclips-eng {
        position: absolute;
        right: 7px;
        width: 186px;
        z-index: 0;
        top: -12px;
        padding-left: 45px;
    }

    #welcome video {
        position: absolute;
        top: 70px;
        left: 332px;
        width: 25%;
        height: 100%;
    }

    .img_custom {
        height: 230px;
        width: 718px;
    }

    section#slider {
        height: 1000px;
    }

    .slider-btn-wrap {
        margin-left: 141px;
        margin-bottom: 40px;
    }

    .download-buttons img {
        margin-left: 39px;
        width: 40%;
    }

    p.or {
        text-align: right;
        margin-right: 25px;
    }

    .slide-text-wrap h1 {
        text-align: right;
        margin-right: 16px;
    }

    .slide-img img {
        padding-right: 10px;
    }

    .slider-btn-wrap .btn-all {
        margin-left: 150px;
    }

    .or {
        margin-left: 100px;
    }

    .download-buttons {
        margin-left: 78px;
    }
}
@media only screen and (min-width: 414px) and (max-width: 767px) {
    .new-mod {
        font-size: 14px;
        line-height: 30px;
        margin-right: 15px !important;
        border-radius: 4px;
        letter-spacing: 0.3px;
    }

    .testimonial .carousel-inner {
        min-height: 377px;
    }

    .carousel {
        padding-top: 42px;
        position: relative;
    }

    .cust_h {
        height: 50%;
        padding: 0px 14px 0px 14px;
    }

    .searchbg {
        height: 100%;
        background: #fff;
        padding: 60px 0;
        padding-left: 42px;
    }

    .testimonial {
        margin-top: -70px;
    }

    .vm_img {
        height: 189px;
        margin-left: 51px;
    }

    #welcome video {
        position: absolute;
        top: 251px;
        left: 128px;
        width: 20%;
        height: 38%;
    }

    section#screen {
        padding: 128px 0;
    }

    .eclips-eng {
        position: absolute;
        right: -3px;
        top: 12px;
        width: 87px;
    }

    section#welcome {
        padding-top: 233px;
    }

    #slider span {
        margin-left: 33px;
    }

    section#slider {
        height: 979px;
    }

    .slide-img img {
        top: 360px;
        margin-left: 40px;
    }

    .download-buttons img {
        margin-top: -10px;
    }

    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
    }
}
@media only screen and (min-width: 375px) and (max-width: 410px) {
    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
        margin-top: -25%;
    }

    .eclips-dec img {
        width: 50%;
    }

    .testimonial .carousel-inner {
        min-height: 400px;
    }

    .cust_h {
        height: 50%;
        padding: 0px 14px 0px 14px;
    }

    .searchbg {
        height: 100%;
        background: #fff;
        padding: 61px 0 0px 46px;
    }

    .testimonial {
        margin-top: -86px;
        padding: 70px 0px;
    }

    .vm_img {
        height: 235px;
        margin-left: 5px;
        margin-top: -14px;
    }

    section#welcome {
        padding-top: 209px;
    }

    .eclips-eng {
        right: -2px;
        top: 33px;
    }

    .vm_video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(25deg)
            skewX(-1.5deg) scaleY(1.15);
    }

    #welcome video {
        position: absolute;
        top: 291px;
        left: 105px;
        width: 20%;
        height: 37%;
    }

    #slider h6 {
        color: #fff;
        margin-left: 151px;
        text-align: right;
    }

    section#screen {
        padding: 151px 0;
        position: relative;
    }

    section#slider {
        height: 951px;
    }

    .slide-img img {
        top: 327px;
        margin-left: 37px;
        width: 300px;
    }

    .slide-text-wrap {
        float: right;
    }

    .download-buttons a img {
        margin-top: 10px;
    }

    p.or {
        padding-left: 206px;
    }

    .slide-text-wrap h1 {
        padding-left: 4px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 374px) {
    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
        margin-top: -60px;
    }

    .eclips-dec img {
        margin-top: 0px;
        width: 35%;
    }

    .carousel {
        padding-top: 87px;
        position: relative;
    }

    .cust_h {
        height: 50%;
        padding: 0px 14px 0px 14px;
    }

    .searchbg {
        height: 100%;
        background: #fff;
        padding: 60px 0;
        padding-left: 49px;
    }

    .testimonial {
        margin-top: -50px;
    }

    .vm_img {
        height: 208px;
        margin-left: 105px;
    }

    section#screen {
        padding: 419px 0;
        position: relative;
    }

    section#slider {
        height: 1000px;
    }

    .footer {
        background: #ffffff;
        padding: 70px 0px 0px 50px;
        border-top: #e5e5e5bd 1px solid;
    }

    .slider-btn-wrap {
        margin-left: 51px;
        margin-bottom: 25px;
        margin-top: 24px;
    }

    .slide-img img {
        top: 28px;
        margin-left: 40px;
    }

    .slide-text-wrap {
        float: right;
    }

    section#welcome {
        background: #f6f5f5;
        position: relative;
        padding-top: 66px;
    }

    .slide-text-wrap h1 span {
        font-size: 34px;
        font-weight: 500;
        padding-left: 6px;
        line-height: 1.263157894736842;
        text-align: right;
    }
}

@media only screen and (min-width: 411px) and (max-width: 413px) {
    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
        margin-top: -25%;
    }

    .eclips-dec img {
        width: 50%;
    }

    .testimonial .carousel-inner {
        min-height: 380px;
    }

    .carousel {
        padding-top: 67px;
        position: relative;
    }

    .cust_h {
        height: 50%;
        padding: 0px 14px 0px 14px;
    }

    .testimonial {
        margin-top: -366px;
    }

    .vm_img {
        height: 203px;
        margin-left: 23px;
    }

    .eclips-eng {
        position: absolute;
        right: -12px;
        top: -29px;
        width: 84px;
    }

    #welcome video {
        position: absolute;
        top: 253px;
        left: 99px;
        width: 50%;
        height: 40%;
    }

    section#screen {
        padding: 83px 0;
        position: relative;
    }

    section#welcome {
        padding-top: 239px;
        padding-bottom: 111px;
    }

    #slider h6 {
        color: #fff;
        text-align: right;
        margin-left: 86px;
    }

    .slider-btn-wrap {
        margin-left: 39px;
        margin-bottom: 40px;
    }

    .footer {
        background: #ffffff;
        padding: 70px 0px 0px 50px;
        border-top: #e5e5e5bd 1px solid;
    }

    .slide-text-wrap h1 {
        text-align: right;
    }

    .slide-img img {
        top: 358px;
        margin-left: 61px;
    }

    .slide-text-wrap {
        margin-top: 0px;
        float: right;
    }

    .searchbg .form-signin {
        width: 80%;
        margin: auto;
        margin-bottom: 5px;
    }

    .download-buttons img {
        margin-top: 10px;
    }

    p.or {
        padding-left: 267px;
    }
}

@media only screen and (min-width: 320px) and (max-width: 359px) {
    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
        top: 15px;
    }

    .download-buttons img {
        margin-top: 10px;
    }

    .slide-text-wrap {
        margin-top: 0px;
        float: right;
    }
}
@media only screen and (min-width: 317px) and (max-width: 319px) {
    .videoWrapper {
        margin-top: 316px;
    }

    .eclips-dec {
        position: absolute;
    }

    .wel-wrap {
        margin-top: 112px;
    }

    section#slider {
        height: 1000px;
    }

    .eclips-dec {
        margin-top: 1px;
    }

    .download-buttons img {
        margin-left: 180px;
        margin-top: 10px;
    }
    .slide-img img {
        top: 160px;
        margin-left: 20px;
    }

    .searchbg .form-signin {
        width: 80%;
        margin: auto;
        margin-bottom: 5px;
    }
}
@media only screen and (min-width: 360px) and (max-width: 374px) {
    .eclips-eng {
        position: absolute;
        right: 0px;
        top: 301px;
        width: 87px;
    }

    #welcome video {
        position: absolute;
        top: 166px;
        left: -38px;
        width: 101%;
        height: 100%;
    }

    section#screen {
        padding: 360px 0;
        position: relative;
    }

    #slider h6 {
        text-align: left;
        color: #fff;
        margin-left: 150px;
    }

    .wel-wrap {
        margin-top: 112px;
    }

    .eclips-dec {
        margin-top: -60px;
        height: 84px;
    }

    section#slider {
        height: 1000px;
    }

    .slide-img img {
        top: 160px;
        margin-left: 20px;
    }
    .download-buttons img {
        margin-top: 10px;
    }

    .searchbg .form-signin {
        width: 80%;
        margin: auto;
        margin-bottom: 5px;
    }

    .footer {
        background: #ffffff;
        padding: 70px 0px 0px 50px;
        border-top: #e5e5e5bd 1px solid;
    }
}

.cust_new {
    margin-bottom: 121px;
}
.engine ul {
    list-style: none;
    list-style-position: outside;
    text-indent: -2em;
    padding-left: 1em;
}

.engine ul li:before {
    content: "✓";
    margin-right: 10px;
    color: #0ea3a7;
    font-size: 20px;
}

.box-wrap ul {
    list-style: none;
    list-style-position: outside;
    text-indent: -2em;
    padding-left: 1em;
}

.box-wrap ul li:before {
    content: "✓";
    color: #0ea3a7;
    font-size: 20px;
    margin-right: 10px;
}

@media only screen and (min-width: 1400px) and (max-width: 700px) {
    #slider {
        height: 660px;
    }
}

/* ---home----- */
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bg-light {
    background-color: #fff !important;
}

.navbar-toggler {
    /* margin: 0 auto; */
    margin: 0 0 0 auto;
}

/* yashi home new 11july */
.hero-image {
    padding: 0px 0 20px 0;
    background: url(https://seatrials-bucket.s3.us-east-2.amazonaws.com/main/banner.jpg)
        no-repeat center center;
    background-size: cover;
    height: 50%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}

.slider-text-wrapper {
    padding-left: 65px;
    margin-top: 180px;
    position: relative;
    padding-right: 0px;
    float: right;
}

.slider-text-wrapper h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.1;
    letter-spacing: 0;
    color: #fff;
    margin-bottom: 25px;
    text-align: right;
    padding-left: 60px;
}

.slider-text-wrapper h1.small-header {
    font-size: 30px;
    font-weight: 300;
}

.slider-text-wrapper h6 {
    color: #fff;
    text-align: right;
    margin-bottom: 35px;
}

.slider-btn-wrapper {
    margin-left: 51px;
    margin-bottom: 40px;
}

.slider-btn-wrapper .btn-all {
    text-transform: uppercase;
    padding: 12px 15px;
    font-size: 18px;
    width: 180px;
    margin-left: 233px;
}

p.orr {
    font-size: 15px;
    text-transform: uppercase;
    position: relative;
    color: #fff;
    margin-bottom: 10px;
    width: auto;
    margin-left: 160px;
    padding-left: 274px;
}

.download-buttons.wow.fadeInUp {
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: right;
}

img.cus_img1 {
    margin-left: 196px;
}

a.btn.btn-primary.my-2.my-sm-0 {
    margin: 0px 5px;
}

img.cus_img1:focus,
img.cus_img1:hover,
img.cus_img2:focus,
img.cus_img2:hover {
    transform: translateY(-5px);
}

.dropdown-toggle::after {
    width: 0;
    height: 0;
    margin-left: 0.55em;
}

.slider-img {
    padding: 100px 0px 75px 0px;
}

.slider-img img {
    width: 87%;
    position: relative;
    bottom: -85px;
    z-index: 9;
    bottom: -95px;
}

section#welcomes {
    background: #f8f8f8;
    position: relative;
    padding-top: 111px;
    padding-bottom: 111px;
}

.eclips-dece {
    position: absolute;
    top: -4px;
}

.eclips-dece img {
    width: 140px;
}

section#screens {
    padding: 1px 0;
    position: relative;
    background: #f8f8f8;
}

.eclips-engg {
    position: absolute;
    right: 7px;
    top: -225px;
    width: 186px;
    z-index: 0;
}

.engin-box-wrap ul {
    list-style: none;
    list-style-position: outside;
    text-indent: -2em;
    padding-left: 1em;
}

.ticker ul li:before {
    content: "✓";
    margin-right: 10px;
    color: #0ea3a7;
    font-size: 20px;
}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
    .text-wrap.h-310 {
        height: 350px !important;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .img-wrap {
        height: 365px;
    }
}

@media only screen and (max-width: 768px) and (min-width: 414px) {
    .eclips-dece img {
        width: 32%;
    }

    .mb-sm-3-my {
        margin-bottom: 25px !important;
    }

    .img-wrap {
        height: 365px;
    }
}

@media only screen and (max-width: 413px) and (min-width: 320px) {
    .eclips-dece img {
        width: 40%;
    }

    .mb-sm-3-my {
        margin-bottom: 25px !important;
    }
}

/* ----Contact Us---- */
@media only screen and (max-width: 768px) {
    .text-center.mb-4.text-center h3 {
        font-size: 18px;
    }
}

/* ----Main Questions---- */

a.forward {
    background: #c8c8c8 !important;
    color: #fff;
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

a.backward {
    background: #c8c8c8 !important;
    color: #fff;
    padding: 10px 20px 10px 20px !important;
    border-radius: 3px;
    margin-right: 5px;
    border: none;
    cursor: pointer;
}

a.forward:hover {
    background: #03c1cf !important;
    color: #fff;
}

a.backward:hover {
    background: #03c1cf !important;
}

button.backward.disabled {
    display: none;
}

/* ----FAQ---- */
.btn.btn-link:hover,
.weare-section .btn:focus,
.weare-section .btn:active {
    color: #03c1cf !important;
    background: none !important;
}
/* ----Search---- */
.searchbg .form-label-group.bt-margin {
    margin-bottom: 10px !important;
}

.searchbg .panel.panel-inverse > .panel-heading {
    color: #fff;
    background: #053042;
}

.searchbg .ReactTable .pagination-bottom .-pagination .-btn:hover,
.ReactTable .pagination-bottom .-pagination .-btn:focus {
    background: #03c1cf;
    color: #fff;
}

.searchbg .ReactTable .pagination-bottom .-pagination .-btn {
    font-weight: 600;
    color: #fff;
    background: #0ea3a7;
    border-radius: 6px;
}

.searchbg .ReactTable .pagination-bottom .-pagination .-btn[disabled]:hover,
.ReactTable .pagination-bottom .-pagination .-btn[disabled]:focus {
    background: #0ea3a7;
}

.searchbg .panel .panel-heading .panel-title {
    font-size: 15px;
}

/* ----Login---- */

.login_logo {
    max-width: 100%;
}

.loginbg .form-label-group .marg-bt {
    margin-bottom: 18px;
}

form#login_form .mt-4 {
    margin-top: 15px !important;
    margin-bottom: 10px !important;
}

.loginbg .form-signin p {
    margin-bottom: 0px !important;
}

.loginbg #login_form .form-label-group {
    margin-bottom: 5px !important;
}

/* User dashboard */
.panel.panel-inverse > .panel-heading {
    color: #fff;
    background: #053042;
}

span.d-none.d-md-inline {
    font-size: 14px;
    color: #222;
    min-height: 50px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 15px 0px;
    text-decoration: none !important;
}

.breadcrumb .breadcrumb-item a {
    color: #02c4d4 !important;
    text-decoration: none;
}

.user .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    margin-bottom: 0 !important;
    font-size: 15px !important;
    padding: 0px 11px !important;
    text-align: right !important;
    background: none !important;
}

/* ----mobile img ----- */

.image-video {
    text-align: center;
}

.img-maxing {
    max-width: 100%;
    z-index: -9999;
}

/* ------yashi------ end*/
/*---------style.css Ends here   ----------*/

@media only screen and (min-width: 768px) {
    .navbar-nav {
        /* float: left; */
        margin: 0;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 768px) {
    .navbar-nav {
        display: flow-root;
    }
}

.navbar-me {
    transition: all 1s;
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
    margin: 0;
    height: 70px !important;
    padding: 25px 15px !important;
    z-index: 999;
}

.videoWrapper {
    margin-left: 160px;
}

.carouselimg img {
    max-width: 100%;
}

.footer.footer-user {
    margin: 0;
}

p.cus_txt_18 {
    font-size: 1.1rem;
    padding-bottom: 47px;
}

section#service-section {
    background: #fff;
}

button#dropdown-button-drop-right {
    background: none;
    color: #606060;
    text-transform: uppercase;
    font-size: 13px !important;
    box-shadow: none;
    margin: 5px 2px 5px 2px;
}

.dropdown-item:hover,
button#dropdown-button-drop-right:hover {
    color: #fff;
    background-color: #0ea3a7 !important;
}

img.gapper {
    margin-left: 5px;
}

@media only screen and (max-width: 768px) {
    .shifter {
        margin-left: 1%;
    }

    .user img.logos {
        margin: 0px 3em 0px;
    }
}

.collapse.in {
    display: block;
}

.directory-page {
    background-color: #fff;
    word-spacing: 2px;
}

a.directory-list {
    padding-right: 20px;
}

button.btn.btn-lg.btn-info,
button.btn.btn-lg.btn-default {
    padding: 10px 10px 10px 10px;
}

h1.weclome-h1 {
    color: #fff;
}

button.btn-lg.btn-link.mt-3.demo4 {
    background: none;
    border: none;
}

button.close {
    margin-left: 320px;
    color: #fff;
}

.modal-header {
    background-color: #0ea3a7;
}

h5.modal-title {
    color: #fff;
    font-size: 15px;
}

.bomb-wrapper.thread-post {
    padding: 10px 0px 0px 0px;
}

.panel-heading-btn button.btn.btn-xs.btn-icon {
    font-size: 8px !important;
}

@media screen and (max-width: 767px) {
}

@media only screen and (min-width: 768px) {
    .navbar-nav {
        /* float: left; */
        margin: 0;
        padding-left: 40px;
        padding-top: 5px;
    }
}

/* #welcomes video {
    transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
    position: absolute;
    top: 148px ;
    left: 359px ;
    width: 20%;
    height: 67%;
} */

/* Small Mobile view style */
#welcomes video {
    transform: perspective(138px) rotateZ(-59.8deg) skewY(27deg) skewX(0.5deg)
        scaleY(1.15);
    position: absolute;
    top: 74%;
    left: 29%;
    width: 23%;
    height: 23%;
}
/*===============*/

@media only screen and (max-width: 768px) {
    .screen-q {
        margin-top: 90px;
    }

    .eclips-engg {
        margin-top: 110px;
        position: absolute;
        z-index: 0;
    }
    .eclips-engg img.cust_all_img {
        width: initial;
    }
    .vm_img {
        height: 203px;
        margin-left: 0px;
    }
}

@media only screen and (max-width: 528px) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 285px ;
        left: 78px ;
        width: 22%;
        height: 40%;
    } */

    .vm_img {
        height: 125px;
        margin-left: 0px;
    }

    .navbar-me {
        transition: all 1s;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.04);
        margin: 0;
        height: 100px !important;
        padding: 25px 15px !important;
        z-index: 999;
    }
}

img.cust_all_img {
    max-width: initial;
}

div#carouselExampleIndicators {
    padding-top: 25px;
}

.engin-box-wrap.ticker {
    height: 50%;
    padding: 0px 30px 0px 30px;
}

/* @media only screen  and (orientation: landscape) {
    #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 169px;
        left: 364px;
        width: 20%;
        height: 60%;
    }
} */

/* @media only screen and (max-width: 1200px) and (orientation: landscape) {
    #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 186px;
        left: 366px;
        width: 23.6%;
        height: 60%;
    }
} */

/* @media only screen and (min-width: 320px) and (max-width: 568px) and (orientation: portrait) {
    #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 304px ;
        left: 78px ;
        width: 25%;
        height: 45%;
    }
} */

@media only screen and (min-width: 360px) and (max-width: 640px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 340px ;
        left: 78px ;
        width: 24%;
        height: 30%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 411px) and (max-width: 731px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 319px ;
        left: 78px ;
        width: 20%;
        height: 30%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 365px) and (max-width: 667px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 305px ;
        left: 78px ;
        width: 22%;
        height: 31%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 823px) and (min-width: 411px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 317px;
        left: 78px;
        width: 20%;
        height: 31%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 720px) and (min-width: 540px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 261px;
        left: 28px;
        width: 69%;
        height: 38%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 720px) and (min-width: 540px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 261px;
        left: 28px;
        width: 69%;
        height: 38%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 653px) and (min-width: 280px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 359px;
        left: 50px;
        width: 50%;
        height: 22%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 640px) and (min-width: 360px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 356px;
        left: 43px;
        width: 50%;
        height: 23%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

/*@media only screen and (min-width: 1024px) and (max-width: 1366px) {
    #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 191px;
        left: 127px;
        width: 84%;
        height: 58%;
    }
   
}*/

@media only screen and (min-width: 768px) and (max-width: 1024) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg)  scaleY(1.13);
        position: absolute;      
        top: 186px;
        left: 73px;
        width: 33%;
        height: 47%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (max-width: 731px) and (min-width: 411px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 333px;
        left: 32px;
        width: 50%;
        height: 24%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 318px;
        left: 75px;
        width: 24%;
        height: 25%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 319px ;
        left: 78px ;
        width: 23%;
        height: 25%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 317px ;
        left: 78px ;
        width: 20%;
        height: 31%;
    } */
    .engin-box-wrap {
        padding: 0px 30px 4px 30px;
    }
}

@media only screen and (min-width: 414px) and (max-width: 736px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 188px;
        left: 120px;
        width: 20%;
        height: 45%;
    } */
}

@media only screen and (min-width: 375px) and (max-width: 667px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 188px;
        left: 120px;
        width: 20%;
        height: 45%;
    } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 187px;
        left: 111px;
        width: 20%;
        height: 45%;
    } */
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 188px;
        left: 219px;
        width: 20%;
        height: 48%;
    } */
    /* .vm_img {
        height: 235px;
        margin-left: 100px;
    } */
}

@media only screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 187px;
        left: 209px;
        width: 20%;
        height: 45%;
    } */
    /* .vm_img {
        height: 200px;
        margin-left: 100px;
    } */
}

img.welcome-desc {
    width: 155px;
    float: right;
    margin-top: 100px;
}

.comment-banner-msg {
    border: 2px solid #dee2e6;
    padding: 0.9375rem 1.25rem;
    text-align: center;
    margin-bottom: 1.25rem;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 1.1rem;
}

.forum-list.forum-detail-list .info-container .post-content {
    font-size: 1.1rem !important;
}

.forum-list.forum-detail-list .info-container .post-user small {
    font-size: 1.1rem !important;
}

.forum-list.forum-detail-list .info-container .post-time {
    font-size: 1.1rem !important;
}

.forum-list > li .info-container .info .title a {
    font-size: 20px !important;
}

.fa-trash {
    cursor: pointer;
}

@media only screen and (max-width: 1366px) and (min-width: 1024px) and (orientation: portrait) {
    /* #welcomes video{
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 161px;
        left: 361px;
        width: 25%;
        height: 68%;
    } */
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    /* #welcomes video {
        transform: perspective(100px) rotateZ(-59.8deg) skewY(27deg) skewX(.5deg) scaleY(1.15);
        position: absolute;
        top: 187px;
        left: 235px;
        width: 17%;
        height: 46%;
    } */

    .engin-box-wrap {
        padding: 0px 0px 0px;
    }

    ul.deck-list li {
        font-size: 12px;
    }
    .navbar .navbar-brand {
        margin-top: -20px;
    }
}

section#welcomesNew {
    background: #f8f8f8;
    position: relative;
    padding-top: 111px;
    padding-bottom: 111px;
}

#welcomesNew .image-video {
    position: relative;
}
#welcomesNew video {
    transform: perspective(138px) rotateZ(-59.8deg) skewY(27deg) skewX(0.5deg)
        scaleY(1.15);
    height: 108px;
    position: absolute;
    top: 2px;
    width: 68px;
    left: 66px;
}

@media only screen and (max-width: 768px) {
    #welcomesNew .vm_img {
        height: 203px;
        margin-left: 0px;
    }

    #welcomesNew video {
        height: 174px;
        position: absolute;
        top: 3px;
        width: 200px;
        left: 63px;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1023px) {
    #welcomesNew video {
        transform: perspective(138px) rotateZ(-59.8deg) skewY(27deg)
            skewX(0.5deg) scaleY(1.15);
        height: 320px;
        position: absolute;
        top: 6px;
        width: 180px;
        left: 232px;
    }
}

@media only screen and (min-width: 1024px) {
    #welcomesNew video {
        transform: perspective(138px) rotateZ(-59.8deg) skewY(27deg)
            skewX(0.5deg) scaleY(1.15);
        height: 333px;
        position: absolute;
        top: 6px;
        width: 187px;
        left: 357px;
    }
}
